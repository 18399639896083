/* eslint-disable jsx-a11y/alt-text */
import './rendering.css';
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";
import html2pdf from 'html2pdf.js';

const axios = require("axios").default;

function ColorLabelInkazenPage() {
	const [isLoaded, setIsLoaded] = useState(false);
	const [colorLabelData, setColorLabelData] = useState(null);
	const search = useLocation().search;

	function PrintPdf() {
		var listPageHtml = document.querySelectorAll('.pdfcontainerPortrait')
		listPageHtml.forEach((data, index) => {
			const productCode = colorLabelData.docIds[index].docId
			var options = {
				filename: productCode + "_colorLabelInkazen.pdf",
				html2canvas: {
					useCORS: true,
					scale: 300 / 72,
				},
				jsPDF: {
					format: "a3",
					unit: "mm"
				}
			}
			html2pdf(data, options);

			console.log("print")
		})
	}

	useEffect(() => {

		const currentIds = new URLSearchParams(search).get('ids');
		const currentToken = new URLSearchParams(search).get('token');
		const currenLocale = new URLSearchParams(search).get('locale');
		//const today =  new Date().toLocaleString("fr-FR");
		document.title = "Color Label Inkazen"

		axios.get("/api/colorLabel", { params: { token: encodeURIComponent(currentToken), ids: currentIds, locale: currenLocale, brand: "INKAZEN" } }).then(resp => {
			if (resp.status == 200) {
				console.log("success", resp.data)
				setColorLabelData(resp.data)

			} else {
				console.log("error", resp.data)
			}
			setIsLoaded(true)
		}).catch(error => {
			console.log(error)
			setIsLoaded(true)
		})

	}, [])

	let dataPdf;


	if (isLoaded) {
		if (colorLabelData != null) {
			let listStructData = []



			const dataLength = colorLabelData.docIds.length
			for (let index = 0; index < dataLength; index++) {
				const element = colorLabelData.docIds[index];
				let last = false;
				if (index == dataLength - 1) {
					console.log("last")
					last = true;
				}

				listStructData.push(<ColorLabelInkazenTemplate data={element} lastElement={last} />)

			}

			dataPdf = listStructData
		} else {
			dataPdf = <div className='pdfLoader'> Erreur dans la récupération des données</div>
		}
	} else {
		dataPdf = <div className='pdfLoader'>Récupération des données...<br />Veuillez Patienter</div>
	}

	return (
		<div style={{ margin: 0, display: "flex", flexDirection: "column" }}>
			<style>
				{`@media print {
	    		    .pdfnextPage {
	            		page-break-after: always;
	          		}	
  
	        		.pdfbuttonContainerCL {
	            		display: none !important;
	          		}
  
	        		.pdfcontainerBorder {
	            		border-bottom: 0px solid black !important;
	          		}
  
	          		.pdfcontainerPortrait {
	            		top: 0px !important;
	          		}
  
	          		@page {
			            margin: 0;
	    		        size: A3 portrait;
	          		}

					body {
			            -webkit-print-color-adjust: exact;
	    			    color-adjust: exact;
	          		}
          		}`}
			</style>
			<div style={{ position: "inherit" }} class="pdfbuttonContainerCL">
				<button class="pdfprintPDFButton" onClick={PrintPdf}>
					Print Inkazen Label
				</button>
			</div>
			{dataPdf}

		</div>

	)
}

function ColorLabelInkazenTemplate(props) {
	const sheetData = props.data
	const isLast = props.lastElement
	console.log(sheetData)

	const nomProduitLines = sheetData.declinaison_nom_reecrit.split("\n").map(line => <p style={styleSheetProductPage.titleText}> {line.toUpperCase()} </p>)

	let isInfoBonus = false;
	if (sheetData.infoBonus)
		isInfoBonus = true;
	const infoBonusLines = sheetData.infoBonus.split("\n").map(line => <p style={styleSheetProductPage.DimensionDescriptif}> {line} <br /> </p>)

	console.log(infoBonusLines)

	useEffect(() => {
		console.log("parse data", isLast)
	}, [])

	//<div style={styleSheetProductPage.mainImgContainer}>
	//<img style={{ width: "328mm", minHeight: "229mm", "z-index": "0", objectFit:"cover" }} src={sheetData.imgMontage1} />
	//</div>

 
	return (
		<div style={{ top: "0px", border: "0px", margin: "0", width: "297mm", height: "420mm","z-index" : "1" }} className={`pdfcontainerPortrait ${isLast == true ? "" : "pdfnextPage"}`}>

			{/* <div style={{height: "296.8mm", "border-left": "3mm solid #1f4d45", left: "101mm", position: "absolute","z-index" : "1"   }}></div> */}
			<div style={{ backgroundSize: "cover", position: "absolute", right: "-2mm", bottom: "10mm","z-index" : "1", width: "145mm"  }}>
				<img style={{width: "100%", "z-index": "0" }} src={sheetData.logobrand} />
			</div>
			{/*<div style={{...styleSheetProductPage.mainImgContainer,...{width: "328mm", minHeight: "229mm", "z-index": "0",backgroundImage: "url(" + sheetData.imgMontage1 + ")",backgroundSize: "cover",backgroundPosition: "center center",backgroundRepeat: "no-repeat"}}}>

	</div>*/}
			<div style={{ width: "297mm", "height": "297mm", "z-index": 0, "left": "0mm", top: "55mm", position : "absolute", "border-bottom-right-radius": "150em 0em", "border-bottom-left-radius": "30em 2em", display:"flex", justifyContent:"center",alignItems:"center", overflow:"hidden"}}>
					<img style={{position: "absolute", top:"50%", left:"50%", transform:"translate(-50%, -50%)", "min-width": "100%", "height": "100%"}} src={sheetData.imgMontage1} />
					{/* <img style={{height:"100%", width:"100%", "object-position":"center"}} src={sheetData.imgMontage1} /> */}
			</div>

			
			{/* <div style={{ backgroundImage: "url(/assets/images/fondColor/INKAZEN_OKOK.png)", position: "absolute", backgroundSize: "cover", top: "0px", border: "0px", margin: "0", width: "100%", height: "100%" }} ></div> */}

			<div style={{top: "0px", border: "0px", margin: "0", position: "absolute"}}>
				<img src="/assets/images/fondColor/INKAZEN_OKOK.png" alt="" style={{height: "100%", width: "100%", backgroundSize: "cover"}}/>
			</div>
			{/* <div style={{ position: "absolute", width: "137.5mm", height: "26.5mm", "background-repeat": "no-repeat", "background-position": "center", right: "20mm", bottom: "25mm" }} ></div> */}

			<div style={styleSheetProductPage.iconBall3}>
				{sheetData.media_packing_picto_avantages1.length > 0 && <img style={{width: "100%", height: "100%","z-index": "1" }} src={sheetData.media_packing_picto_avantages1} />}
			</div>
			<div style={styleSheetProductPage.iconBall2}>
				{sheetData.media_packing_picto_avantages2.length > 0 && <img style={{width: "100%", height: "100%","z-index": "1" }} src={sheetData.media_packing_picto_avantages2} />}
			</div>
			<div style={styleSheetProductPage.iconBall1}>
				{sheetData.media_packing_picto_avantages3.length > 0 && <img style={{width: "100%", height: "100%","z-index": "1" }} src={sheetData.media_packing_picto_avantages3} />}
			</div>

			<div style={styleSheetProductPage.brandLogoContainer}>
				{nomProduitLines}
			</div>

			<div style={styleSheetProductPage.plusProduitContainer}>
				<div style={styleSheetProductPage.plusProduitTextContainer}>
					<p style={styleSheetProductPage.plusProduitText}>{sheetData.infoPlus1}</p>
				</div>
				<div style={styleSheetProductPage.plusProduitTextContainer}>
					<p style={styleSheetProductPage.plusProduitText}>{sheetData.infoPlus2}</p>
				</div>
				<div style={styleSheetProductPage.plusProduitTextContainer}>
					<p style={styleSheetProductPage.plusProduitText}>{sheetData.infoPlus3}</p>
				</div>
			</div>

			<div style={styleSheetProductPage.dimensionContainer}>
				<p style={styleSheetProductPage.titleDimension}>Matériaux : <span style={styleSheetProductPage.DimensionDescriptif}>{sheetData.infoMateriaux}</span></p>
				{isInfoBonus && <p><span style={styleSheetProductPage.DimensionDescriptif}>{infoBonusLines}</span></p>}
			</div>


			<div style={styleSheetProductPage.recyclageContainer}>
				<p style={styleSheetProductPage.refText}>Réf : {sheetData.docId}</p>
				<p style={styleSheetProductPage.refText}>Réf log : {sheetData.refColis}</p>
			</div>

			<div style={styleSheetProductPage.infoUsineContainer}>
				<p style={styleSheetProductPage.refTextImport}>{sheetData.infoUsine}</p>
			</div>

		</div>
	)
}

const styleSheetProductPage = {
	mainImgContainer: {
		position: "absolute",
		top: "0mm",
		right: "0mm",
	},
	iconBall3 : {
		height: "43mm",
		"z-index" : "1",
		right: "3mm",
		position: "absolute",
		"border-radius": "100mm",
		width: "43mm",
		top: "301mm"
	},
	iconBall2 : {
		"z-index" : "1",
		height: "43mm",
		right: "48mm",
		position: "absolute",
		"border-radius": "100mm",
		width: "43mm",
		top: "317mm"
	},
	iconBall1 : {
		"z-index" : "1",
		height: "43mm",
		right: "97mm",
		position: "absolute",
		"border-radius": "100mm",
		width: "43mm",
		top: "325mm"
	},
	infoPlusDot1: {
		height: "4mm",
		width: "4mm",
		position: "absolute",
		left: "8mm",
		bottom: "43.5mm",
		"background-color": "#d2bba9",
		"border-radius": "100%"
	}, 
	infoPlusDot2: {
		height: "4mm",
		width: "4mm",
		position: "absolute",
		left: "8mm",
		bottom: "35.5mm",
		"background-color": "#d2bba9",
		"border-radius": "100%"
	}, 
	infoPlusDot3: {
		height: "4mm",
		width: "4mm",
		position: "absolute",
		left: "8mm",
		bottom: "27.5mm",
		"background-color": "#d2bba9",
		"border-radius": "100%"
	}, 
	brandLogoContainer: {
		position: "absolute",
		left: "10mm",
		top: "6mm",
		width: "255mm",
		height: "42mm",
		"z-index" : "1",
		display: "flex",
		"flex-direction": "column",
		"justify-content": "center"
	},
	img2Container: {
		"z-index" : "1",
		position: "absolute",
		bottom: "47mm",
		left: "150mm",
	},
	infoBonusLines: {
		"z-index" : "1",
		color: "#000000",
		"font-size": "15pt",
		"font-family": "Jost-Book",
	},
	plusProduitContainer: {
		"z-index" : "1",
		position: "absolute",
		left: "21mm",
		bottom: "40mm",
		height: "24mm",
		display: "flex",
		"flex-direction": "column",
		"justify-content": "space-between",
	},
	dimensionContainer: {
		"z-index" : "1",
		position: "absolute",
		bottom: "5mm",
		left: "13mm",
		"flex-direction": "column",
		"align-items": "flex-start",
		// "justify-content": "center",
		height: "28mm",
		width: "143mm",
		display: "flex",
	},
	DimensionDescriptif: {
		"z-index" : "1",
		"font-family": "Roboto-Regular",
		"font-size": "12pt",
		"line-height": "16pt",
		color: "#1f4d45"
	},
	materiauxContainer: {
		"z-index" : "1",
		position: "absolute",
		left: "163mm",
		top: "345mm",
	},
	recyclageContainer: {
		"z-index" : "1",
		position: "absolute",
		bottom: "11mm",
		//"text-align": "center",
		right: "12mm",
		width: "100mm",
		height: "8mm",
		display: "flex",
		"flex-direction": "column",
		"align-items": "flex-end"
	},
	plusProduitTextContainer: {
		height: "4mm",
		"z-index" : "1",
		width: "130mm",
		display: "flex",
	},
	infoBonusLinesContainer: {
		position: "absolute",
		"z-index" : "1",
		bottom: "0mm",
		left: "112mm",
		height: "20mm",
		width: "152.5mm",
		display: "flex",
		"flex-direction": "column",
		"justify-content": "center",
	},
	plusProduitText: {
		color: "#1f4d45",
		"font-family": "Roboto-Medium",
		"font-size": "19pt",
		"line-height": "19pt"
	},
	refText: {
		"font-family": "Roboto-Regular",
		"font-size": "9pt",
		"line-height": "12pt",
		color: "#1f4d45"
	},
	refTextImport: {
		"font-family": "Roboto-Regular",
		"font-size": "9pt",
		"line-height": "16pt",
		color: "#1f4d45"
	},
	titleText: {
		"font-family": "Roboto-Black",
		"font-size": "40pt",
		"z-index" : "1",
		"line-height": "42pt",
		"text-transform": "uppercase",
		color: "#FFFFFF",
	},
	titleMateriaux: {
		"font-family": "Roboto-Regular",
		"font-size": "13pt",
		"line-height": "16pt",
		color: "rgb(0,0,0,0.4)"
	},
	titleDimension: {
		"font-family": "Roboto-Regular",
		"font-size": "12pt",
		"line-height": "16pt",
		color: "#1f4d45"
	},
	dataDimension: {
		"font-family": "Roboto-Regular",
		"font-size": "11pt",
		"line-height": "12pt",
		color: "#1f4d45"
	},
	infoUsineContainer: {
		"z-index" : "1",
		position: " absolute",
		right: "12mm",
		bottom: "6mm",
		width: "140mm",
		height: "4mm",
		display: "flex",
		"justify-content": "flex-end"
	}
}

export default ColorLabelInkazenPage